<template>
  <a-modal @cancel="closeModal" :width="440" title="更换手机号码" :visible="show" wrapClassName="phone-modal" okText="绑定手机号" cancelText="取消">
    <p class="reset-tips">真实的手机号可用于接收短信相关的提醒推送</p>
    <a-form>
      <a-form-item label="验证密码">
        <a-input-password v-model="form.password"></a-input-password>
      </a-form-item>
      <a-form-item label="想要绑定的手机号">
        <a-input-group compact>
          <a-select style="width: 20%" v-model="form.phoneCode">
            <a-select-option value="86">+86</a-select-option>
          </a-select>
          <a-input type="tel" style="width: 80%" v-model="form.phone"></a-input>
        </a-input-group>
      </a-form-item>
      <a-form-item label="验证码">
        <a-input class="code-input" v-model="form.code"></a-input>
        <el-button type="primary" class="reset-btn">获取短信验证码</el-button>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'PhoneModal',
  props: {
    close: {
      type: Function
    },
    show: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    formList: {
      type: Object,
      default: () => {
        return {
          password: '',
          phone: '',
          phoneCode: '86',
          code: ''
        }
      }
    }
  },
  data() {
    return {
      form: this.formList
    }
  },
  methods: {
    closeModal() {
      this.$emit('close', this.name)
    }
  }
}
</script>

<style scoped lang="scss">
.reset-tips {
  padding: 6px 20px;
  margin: 0;
  color: #6b737b;
  font-size: 12px;
  background: #fafafb;
}

::v-deep .phone-modal {
  .ant-modal-content {
    .ant-modal-body {
      padding: 0;

      .ant-form {
        padding: 16px 20px;
      }
    }

    .reset-tips {
      font-size: 14px;
    }

    .reset-btn {
      margin-top: 8px;
    }

    .code-input {
      width: 80%;
    }
  }
}
</style>
