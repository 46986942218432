<template>
  <div class="card-content">
    <div class="card-item">
      <div class="card-icon">
        <a-avatar size="large" icon="bell" />
        <div class="card-container">
          <div class="container-title">{{ $lang.t('personal.desktop_notification') }}</div>
          <div class="size-small gray-normal">{{ $lang.t('personal.desktop_msg') }}</div>
        </div>
      </div>

      <div class="card-switch">
        <a-switch />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Remind',
  data() {
    return {
    }
  }
}
</script>

<style scoped lang="scss">
.card-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-content{
  width: 100%;
  .card-item{
    width: 100%;
    margin-bottom: 1px;
    padding: 20px 40px 20px 30px;
    background-color: #fff;
    border-radius: 3px 3px 0 0;
    &:last-child{
      border-radius: 0 0 3px 3px;
    }
    .card-icon{
      display: flex;
      .ant-avatar{
        background-image: linear-gradient(-45deg, #43b9f9 1%, #225a9e);
        margin-right: 14px;
      }
    }
    .card-container{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .container-title{
        margin-bottom: 3px;
      }
    }
  }
}
</style>
