<template>
  <a-modal @cancel="closeModal" :width="440" title="更换绑定邮箱" :visible="show" wrapClassName="email-modal" okText="确定" cancelText="取消">
    <p class="reset-tips">邮箱更换成功后，将通过最新邮箱进行登录</p>
    <a-form layout="horizontal" :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
      <a-form-item label="验证密码">
        <a-input></a-input>
      </a-form-item>
      <a-form-item label="新的邮箱">
        <a-input></a-input>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'EmailModal',
  props: {
    close: {
      type: Function
    },
    name: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    },
    formList: {
      type: Object,
      default: () => {
        return {
          password: '',
          phone: '',
          phoneCode: '86',
          code: ''
        }
      }
    }
  },
  data() {
    return {
      form: this.formList
    }
  },
  methods: {
    closeModal() {
      this.$emit('close', this.name)
    }
  }
}
</script>

<style scoped lang="scss">
.reset-tips {
  padding: 6px 20px;
  margin: 0;
  color: #6b737b;
  font-size: 12px;
  background: #fafafb;
}

::v-deep .email-modal {
  .ant-modal-content {
    .ant-modal-body {
      padding: 0;

      .ant-form {
        padding: 16px 20px;
      }
    }

    .reset-tips {
      font-size: 14px;
    }

    .reset-btn {
      margin-top: 8px;
    }

    .code-input {
      width: 80%;
    }
  }
}
</style>
