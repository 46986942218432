<template>
  <div class="commissioned-content">
    <div class="content-header">
      <div class="tab-list">
        <div class="tab-item" :class="{active:type === 'entrust'}" @click="type = 'entrust'"><span>委托任务</span></div>
        <div class="tab-item" :class="{active:type !== 'entrust'}" @click="type = 'entrusted'"><span>受委托任务</span></div>
      </div>
      <div class="button-list">
        <a-button icon="plus-circle" type="dashed">发起委托</a-button>
      </div>
    </div>
    <div class="content-table">
      <a-table :columns="columns" :data-source="data" :pagination="{'show-total':total => `共${total}页`}" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Commissioned',
  data() {
    return {
      type: 'entrust',
      columns: [
        {
          dataIndex: 'name',
          key: 'name',
          title: '发起时间'
        },
        {
          title: '受委托人',
          dataIndex: 'age',
          key: 'age'
        },
        {
          title: '委托范围',
          dataIndex: 'address',
          key: 'address'
        },
        {
          title: '开始时间',
          key: 'tags',
          dataIndex: 'tags'
        },
        {
          title: '结束时间',
          key: 'action',
          dataIndex: 'action'
        }
      ],
      data: []

    }
  }
}
</script>

<style scoped lang="scss">
.commissioned-content{
  width: 100%;
  .content-header{
    background: #fff;
    height: 48px;
    display: flex;
    justify-content: space-between;
    .tab-list{
      display: flex;
      align-items: center;
      .tab-item{
        display: flex;
        align-items: center;
        position: relative;
        margin: 0 16px;
        height: 100%;
        cursor: pointer;
        &:after{
          display: none;
          position: absolute;
          bottom: -1px;
          left: 0;
          width: 100%;
          height: 3px;
          z-index: 1;
          background: #ffbd0f;
          border-radius: 2px;
          content: "";
        }

        &.active{
          &:after{
            display: block;
          }
          span {
            color: #2b3441;
            font-weight: 500;
          }
        }
        span{
          font-size: 16px;
          color: rgba(43,52,65,.6);
          cursor: pointer;

        }
      }
    }
    .button-list{
      display: flex;
      align-items: center;
      button{
        margin-right: 20px;
        margin-left: 8px;
      }
    }
  }
  .content-table{
    background-color: #fff;
  }
}
</style>
