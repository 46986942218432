<template>
  <div class="content-main">
    <div class="warp">
      <!--<div class="left-side">-->
      <!--  <a-menu v-model="leftActive" class="warp-menu" @click="changeMenu">-->
      <!--    <a-menu-item key="account">{{ $lang.t('personal.account') }}</a-menu-item>-->
      <!--    &lt;!&ndash;          <a-menu-item key="commissioned">任务委托</a-menu-item>&ndash;&gt;-->
      <!--    &lt;!&ndash;          <a-menu-item key="remind">{{ $lang.t('personal.notify') }}</a-menu-item>&ndash;&gt;-->
      <!--  </a-menu>-->
      <!--</div>-->
      <div class="right-content">
        <account-view v-if="leftActive[0] === 'account'" :form="form" @formChange="formChange" />
        <remind v-if="leftActive[0] === 'remind'" />
        <commissioned v-if="leftActive[0] === 'commissioned'" />
      </div>
    </div>
  </div>
</template>

<script>
import accountView from './sub-components/account'
import remind from './sub-components/remind'
import commissioned from '@/views/personal/sub-components/commissioned'
import {
  useDetail
} from '@/api/user'
export default {
  name: 'PersonalCenter',
  components: { accountView, remind, commissioned },
  data() {
    return {
      leftActive: ['account'],
      form: {}
    }
  },
  created() {
    this.getUseDetail()
  },
  methods: {
    changeMenu(e) {
      this.leftActive[0] = e.key
    },
    getUseDetail() {
      useDetail({ userId: '', enterpriseId: '' }).then((res) => {
        if (res['code'] !== 0) {
          this.$antMessage.error(res['message'])
          return
        }
        this.form = res.data
        this.$store.dispatch('user/setUserInfo', {
          nickName: res.data.nickName,
          portraitFileUrl: res.data.portraitFileUrl
        })
      })
    },
    formChange() {
      this.getUseDetail()
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  .content-main {
    background-color: #f5f5f7;

    .warp {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      max-width: 1280px;
      margin: auto;

      .left-side {
        width: 200px;
        flex-shrink: 0;
        margin-right: 80px;
        border-radius: 3px;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .1);

        .warp-menu {
          width: 100%;
          padding: 8px 0;
          background: #fff;
        }
      }

      .right-content {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex: 1;
      }
    }
  }
}
</style>
