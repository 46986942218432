<template>
  <a-modal
    :width="440"
    title="修改密码"
    :visible="show"
    wrapClassName="email-modal"
    okText="确定修改"
    cancelText="取消"
    @ok="handleOk('ruleForm')"
    @cancel="closeModal('ruleForm')"
  >
    <a-form-model
      layout="horizontal"
      ref="ruleForm"
      :model="ruleForm"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 16 }"
      :rules="rules"
    >
      <a-form-model-item label="旧密码" prop="password">
        <a-input type="password" v-model="ruleForm.password"></a-input>
      </a-form-model-item>
      <a-form-model-item label="新密码" prop="newPassword">
        <a-input-password v-model="ruleForm.newPassword"></a-input-password>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
export default {
  name: 'PasswordModal',
  props: {
    close: {
      type: Function
    },
    name: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    },
    formList: {
      type: Object,
      default: () => {
        return {
          oldPassword: '',
          newPassword: ''
        }
      }
    }
  },
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入旧密码'));
      } else {
        if (this.ruleForm.newPassword !== '') {
          this.$refs.ruleForm.validateField('newPassword');
        }
        callback()
      }
    }
    let validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'));
      } else if (value == this.ruleForm.password) {
        callback(new Error("新密码不能与旧密码相同"));
      } else {
        callback();
      }
    }
    return {
      ruleForm: this.formList,
      rules: {
        // name: [
        //   { required: true, message: 'Please input Activity name', trigger: 'blur' },
        //   { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' },
        // ],
        password: [
          { min: 6, max: 16, message: '密码长度需在 6-16 之间', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' },
        ],
        newPassword: [
          { min: 6, max: 16, message: '密码长度需在 6-16 之间', trigger: 'blur' },
          { validator: validatePass2, trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    closeModal(ruleForm) {
      this.$refs[ruleForm].resetFields()
      this.ruleForm = {}
      this.$emit('close', this.name)
    },
    handleOk(ruleForm) {
      this.$refs[ruleForm].validate(valid => {
        if (valid) {
          this.$emit('handleOk', this.ruleForm)
        } else {
          return false;
        }
      });

    }
  }
}
</script>

<style scoped lang="scss">

::v-deep .email-modal {
  .ant-modal-content {
    .ant-modal-body {
      padding: 0;

      .ant-form {
        padding: 16px 20px;
      }
    }

    .reset-btn {
      margin-top: 8px;
    }

    .code-input {
      width: 80%;
    }
  }
}
</style>
