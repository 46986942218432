<template>
  <div class="card-content">
    <div class="card-item">
      <div class="item-header">{{ $lang.t('personal.basic_information') }}</div>
      <div class="item-container">
        <div class="container-child">
          <span class="child-left-title">{{ $lang.t('personal.avatar') }}</span>
          <a-upload
            name="file"
            accept="image"
            :show-upload-list="false"
            :before-upload="beforeUpload"
            :custom-request="customRequest"
            class="mr-10"
          >
            <a-avatar v-if="isUpload" :loading="loading" :size="50" shape="square" :src="fileData.fileUrl" class="mr-10" />
            <a-avatar v-else :size="50" :loading="loading" icon="user" shape="square" :src="form.portraitFileUrl ? form.portraitFileUrl : undefined" class="mr-10" />
            <a-button class="head-img-btn" :loading="loading">{{ $lang.t('personal.modify') }}</a-button>
          </a-upload>
          <a-button v-if="isUpload" type="primary" class="mr-10" @click="saveChange">{{ $lang.t('personal.save') }}</a-button>
        </div>
        <div class="container-child">
          <span class="child-left-title">{{ $lang.t('personal.name') }}</span>
          <a-input v-if="is_edit_name" v-model="form.nickName" class="user-info-input" />
          <div v-else class="user-info user-name">{{ form.nickName }}</div>
          <a-button class="head-img-btn" :type="is_edit_name?'primary':'default'" :class="{'head-img-warn':is_edit_name}" @click="editName">
            {{ $lang.t('personal.modify') }}
          </a-button>
          <a-button v-if="is_edit_name" class="head-img-btn" @click="is_edit_name = !is_edit_name">{{ $lang.t('personal.cancel') }}</a-button>
        </div>
        <div class="container-child">
          <span class="child-left-title">{{ $lang.t('personal.mobile') }}</span>
          <div class="user-info user-name">{{ form.tel }}</div>
          <!--          <a-button class="head-img-btn" @click="is_edit_phone = !is_edit_phone">修改</a-button>-->
        </div>

        <!--        <div class="container-child">-->
        <!--          <span class="child-left-title">{{ $lang.t('personal.lang') }}</span>-->
        <!--          <a class="lang" @click="changeLang">-->
        <!--            <span>{{ form.is_zh_CN ? 'English' : '简体中文' }}</span>-->
        <!--            <a-icon type="global" />-->
        <!--          </a>-->
        <!--        </div>-->
      </div>
    </div>
    <div class="card-item">
      <div class="item-header">{{ $lang.t('personal.safety_information') }}</div>
      <div class="item-container">
        <!--            <div class="container-child">-->
        <!--              <span class="child-left-title">邮箱</span>-->
        <!--              <div class="user-info user-name">{{ form.email }}</div>-->
        <!--              <a-button class="head-img-btn" @click="showEditEmail">修改</a-button>-->
        <!--            </div>-->
        <div class="container-child">
          <span class="child-left-title">{{ $lang.t('personal.password') }}</span>
          <div class="user-info user-name">{{ password_lock }}</div>
          <a-button class="head-img-btn" @click="showPassword">{{ $lang.t('personal.modify') }}</a-button>
        </div>
      </div>
    </div>
    <!--<div class="card-item">-->
    <!--  <div class="item-header">绑定信息</div>-->
    <!--  <div class="item-container">-->
    <!--    <div class="container-child">-->
    <!--      <span class="child-left-title">微信</span>-->
    <!--      <div class="child-wechat">-->
    <!--        <a-avatar shape="square" size="large" icon="wechat" />-->
    <!--        未绑定-->
    <!--      </div>-->
    <!--      <a-button class="head-img-btn">如何绑定? </a-button>-->
    <!--    </div>-->
    <!--  </div>-->
    <!--</div>-->
    <phoneModal name="is_edit_phone" :show="is_edit_phone" @close="closeModal" />
    <emailModal name="is_edit_email" :show="is_edit_email" @close="closeModal" />
    <passwordModal
      v-if="is_edit_password"
      name="is_edit_password"
      :form-list="formList"
      :show="is_edit_password"
      @close="closeModal"
      @handleOk="handleOk"
    />
  </div>
</template>

<script>
import phoneModal from '@/components/common/phoneModal'
import emailModal from '@/components/common/emailModal'
import passwordModal from '@/components/common/passwordModal'
import { userUpdate, userPasswordUpdate, uploadUserPortrait, modifyUserPortrait } from '@/api/user'
import { logout } from '@/api/login'
export default {
  name: 'Account',
  components: { phoneModal, emailModal, passwordModal },
  props: {
    form: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      is_edit_email: false,
      is_edit_password: false,
      is_edit_name: false,
      is_edit_phone: false,
      password_lock: '******',
      // form: {
      //   fileUrl: '',
      //   name: '孔',
      //   phone: '18127414762',
      //   is_zh_CN: true,
      //   password: '123456',
      //   password_lock: '******',
      //   email: '569214475@qq.com'
      // },
      headers: {
        authorization: 'authorization-text'
      },
      isUpload: false,
      formList: {
        userId: '',
        password: '',
        newPassword: ''
      },
      file: {},
      fileData: {},
      loading: false
    }
  },
  methods: {
    closeModal(name) {
      this[name] = false
    },
    changeLang() {
      const type = this.form.is_zh_CN ? 'zh-cn' : 'en'
      this.$store.dispatch('app/SET_LOCALE', type).then(() => {
        this.$lang.locale = type
        this.form.is_zh_CN = !this.form.is_zh_CN
      })
    },
    showEditEmail() {
      this.is_edit_email = true
    },
    showPassword() {
      this.is_edit_password = true
    },
    handleOk(formList) {
      const that = this
      this.$confirm({
        title: '是否确定修改密码?',
        content: '密码修改成功后账户会自动登出，需重新登录',
        onOk() {
          that.formList = formList
          that.formList.userId = that.form.userId
          userPasswordUpdate(that.formList).then((res) => {
            if (res['code'] !== 0) {
              this.$antMessage.error(res['message'])
              return
            }
            that.$antMessage.success(res['message'])
            logout().then((res) => {
              if (res['code'] !== 0) {
                that.$antMessage.error(res['message'])
                return
              }
            })
          })
        },
        cancelText: '取消',
        onCancel() {
          that.$destroyAll()
        }
      })
    },
    // 编辑名称
    editName() {
      if (this.is_edit_name) {
        const data = {
          email: this.form.email,
          tel: this.form.tel,
          userName: this.form.userName,
          userId: this.form.userId,
          nickName: this.form.nickName
        }
        userUpdate(data).then((res) => {
          if (res['code'] !== 0) {
            this.$antMessage.error(res['message'])
            return
          }
          this.is_edit_name = !this.is_edit_name
          this.$emit('formChange', 'name')
        })
      } else {
        this.is_edit_name = true
      }
    },
    // 上传前
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$antMessage.error('您只能上传 JPG 或 png 文件!')
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isLt2M) {
        this.$antMessage.error('上传图片不能超过5M!')
        return false
      }
      this.file = file
      this.isUpload = true
    },
    // 上传文件
    customRequest() {
      this.loading = true
      const data = new FormData()
      data.append('file', this.file)
      data.append('userId', '')
      data.append('enterpriseId', '')
      uploadUserPortrait(data).then((res) => {
        if (res['code'] !== 0) {
          this.$antMessage.error(res['message'])
          return
        }
        this.fileData = res.data
        this.loading = false
      })
    },
    // 确认修改头像
    saveChange() {
      const data = {
        portraitFileCode: this.fileData.fileCode,
        userId: '',
        enterpriseId: ''
      }
      modifyUserPortrait(data).then((res) => {
        if (res['code'] !== 0) {
          this.$antMessage.error(res['message'])
          return
        }
        this.isUpload = false
        this.$emit('formChange', 'file', this.fileData)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.card-content {
  width: 100%;

  .card-item {
    width: 100%;
    margin-bottom: 20px;
    background: #fff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;

    .item-header {
      width: 100%;
      height: 50px;
      padding-left: 20px;
      font-size: 15px;
      border-bottom: 1px solid #f5f5f7;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .item-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding: 10px 20px 15px;

      .container-child {
        width: 100%;
        margin: 15px 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .child-left-title {
          margin-right: 20px;
          color: #b0b0b9;
        }

        .user-info {
          width: 300px;
          height: 34px;
          padding-left: 10px;
          background-color: #f5f5f7;
          border-radius: 3px;

          &.user-name {
            display: block;
            line-height: 34px;
          }
        }

        .user-name {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
        .child-wechat{
          width: 300px;
        }
        .user-info-input {
          width: 300px;
        }

        .lang {
          color: rgba(0, 0, 0, .45);

          span {
            display: inline-block;
            padding-right: 8px;
          }
        }

        .head-img-btn {
          margin-left: 20px;
        }
      }
    }
  }
}
</style>
